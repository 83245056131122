import React, { Component } from 'react';

import {
    Form,
    TextInput,
    Container,
    Loading,
    FileInput,
    Alert,
    Button,
} from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class EditLocation extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = `/api/home/location/`;
        if ('id' in params) {
            url = `${url}${params['id']}/`;
        }

        this.state = {
            loaded: false,
            location: { id: null },
            parents: [],
            children: [],

            url: url,
            location_updated: false,
            parent_added: false,
            parent_error: '',
        };

        this.update_relationships = this.update_relationships.bind(this);
        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
        this.submit_new_callback = this.submit_new_callback.bind(this);
        this.add_parent = this.add_parent.bind(this);
        this.add_parent_callback = this.add_parent_callback.bind(this);
    }

    componentDidMount() {
        ajax_wrapper(
            'GET',
            this.state.url,
            {},
            function (value) {
                this.setState({ loaded: true, location: value });
            }.bind(this),
        );

        this.update_relationships();
    }

    update_relationships() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/home/location/list/?child_positions__child=${params['id']}`,
            {},
            function (value) {
                this.setState({ loaded: true, parents: value });
            }.bind(this),
        );

        ajax_wrapper(
            'GET',
            `/api/home/location/list/?positions_on_parent__parent=${params['id']}`,
            {},
            function (value) {
                this.setState({ loaded: true, children: value });
            }.bind(this),
        );
    }

    submit(data) {
        ajax_wrapper(
            'POST',
            this.state.url,
            {
                name: data['name'],
                image: data['image'],
                map_maker_name: data['map_maker_name'],
                map_maker_url: data['map_maker_url'],
                seo_image: data['seo_image'],
            },
            this.submit_callback,
        );
    }

    submit_callback(data) {
        if (data['success']) {
            this.setState({ location_updated: true });
        }
    }

    submit_new_callback(data) {
        window.location.href = `/admin/edit_location/${data['id']}`;
    }

    add_parent(data) {
        ajax_wrapper(
            'POST',
            '/api/add_parent_to_location/',
            {
                id: this.state.location.id,
                parent_name: data['parent_name'],
            },
            this.add_parent_callback,
        );
    }

    add_parent_callback(data) {
        if (data['success']) {
            this.setState({ parent_added: true }, this.update_relationships);
        } else if (data['error']) {
            this.setState({ parent_error: data['error'] });
        }
    }

    render() {
        let parent_alert = null;
        if (this.state.parent_error) {
            parent_alert = (
                <Alert type="danger">{this.state.parent_error}</Alert>
            );
        } else if (this.state.parent_added) {
            parent_alert = <Alert type="success">Parent Added</Alert>;
        }

        let location_alert = null;
        if (this.state.location_updated) {
            location_alert = <Alert type="success">Location Updated</Alert>;
        }

        let add_location_position = null;
        if (this.state.location) {
            add_location_position = (
                <div>
                    <h3>Add Location Position</h3>
                    <Form submit={this.add_parent}>
                        <TextInput name="parent_name" label="Parent Name" />
                    </Form>
                    {parent_alert}
                </div>
            );
        }

        let parents = [];
        for (let item of this.state.parents) {
            parents.push(
                <Button
                    type="outline-primary"
                    href={`/admin/edit_location/${item['id']}/`}
                >
                    {item['name']}
                </Button>,
            );
        }

        let children = [];
        for (let item of this.state.children) {
            children.push(
                <Button
                    type="outline-primary"
                    href={`/admin/edit_location/${item['id']}/`}
                >
                    {item['name']}
                </Button>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <h3>Edit Info</h3>

                            {location_alert}
                            <Form
                                defaults={this.state.location}
                                submit={this.submit}
                                key={this.state.location.id}
                            >
                                <TextInput name="name" label="Name" />
                                <FileInput name="image" label="Image" />
                                <TextInput
                                    name="map_maker_name"
                                    label="Map Maker Name"
                                />
                                <TextInput
                                    name="map_maker_url"
                                    label="Map Maker URL"
                                />
                                <FileInput name="seo_image" label="SEO Image" />
                            </Form>
                        </div>
                        <div className="simple-card">
                            {add_location_position}
                        </div>

                        <div className="simple-card">
                            <h5>Parents</h5>
                            {parents}
                        </div>

                        <div className="simple-card">
                            <h5>Children</h5>
                            {children}
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
