import React, { Component } from 'react';

import { Container, Link } from 'library';

export default class AdminDashboard extends Component {
    render() {
        return (
            <Container>
                <div>
                    <h2>Admin Dashboard</h2>
                </div>
                <div>
                    <h4>Models</h4>

                    <div className="simple-card-container">
                        <div className="simple-card">
                            <Link href="/admin/list/home/wikipage/">
                                WikiPage
                            </Link>
                        </div>
                        <div className="simple-card">
                            <Link href="/admin/list/home/type/">Type</Link>
                        </div>
                        <div className="simple-card">
                            <Link href="/admin/list/home/location/">
                                Location
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}
