import React, { Component } from 'react';

import { ajax_wrapper, set_global_state } from 'functions';
import { Loading } from 'library';

class ContextLoader extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false };

        this.load_user = this.load_user.bind(this);
    }

    componentDidMount() {
        ajax_wrapper('GET', '/user/user/', {}, this.load_user);
    }

    load_user(value) {
        set_global_state('user', value);

        this.setState({
            loaded: true,
        });
    }

    render() {
        return (
            <Loading loaded={this.state.loaded}>{this.props.children}</Loading>
        );
    }
}

export default ContextLoader;
