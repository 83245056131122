import React, { Component } from 'react';

import { Line, Group } from 'react-konva';

export default class Geojson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coordinates: [...this.props.geometry['coordinates']],
        };
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.geometry['coordinates']) !==
            JSON.stringify(this.state.coordinates)
        ) {
            this.setState({
                coordinates: [...this.props.geometry['coordinates']],
            });
        }
    }

    render() {
        let features = [];
        if (this.props.geometry['type'] == 'LineString') {
            features.push(
                <Line
                    points={this.state.coordinates}
                    stroke={'red'}
                    strokeWidth={1}
                    closed={false}
                    tension={0.5}
                />,
            );
        } else if (this.props.geometry['type'] == 'MultiPolygon') {
            features.push(
                <Line
                    points={this.state.coordinates}
                    stroke={'rgba(255,0,0,1)'}
                    fill={'rgba(255,0,0,.2)'}
                    strokeWidth={1}
                    closed={true}
                />,
            );
        }

        return (
            <Group
                key="geojson_group"
                onClick={this.props.onClick}
                x={this.props.x || 0}
                y={this.props.y || 0}
            >
                {features}
            </Group>
        );
    }
}
