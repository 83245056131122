import React, { Component } from 'react';

import { Button, Form, TextArea, Loading, Select, TextInput } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';
import { CampaignSettings } from 'components';

class Note extends Component {
    constructor(props) {
        super(props);

        this.delete_note = this.delete_note.bind(this);
    }

    delete_note() {
        ajax_wrapper(
            'POST',
            '/api/delete_campaign_note/' + this.props.note.id + '/',
            {},
            this.props.refresh_notes,
        );
    }

    render() {
        let note = this.props.note;
        let background_color = 'white';
        if (note.private) {
            background_color = 'tomato';
        }

        let user = window.secret_react_state.user;

        let delete_button = null;
        if (this.props.dm || (user && note.user && note.user.id == user.id)) {
            delete_button = (
                <Button onClick={this.delete_note} type="danger">
                    <i class="bi bi-trash"></i>
                </Button>
            );
        }

        if (
            note.private &&
            (!user || (note.user.id != user.id && !this.props.dm))
        ) {
            return null;
        } else {
            return (
                <div
                    className="card"
                    style={{
                        padding: '10px',
                        backgroundColor: background_color,
                    }}
                >
                    <p style={{ marginBottom: '0px' }}>
                        <b>{note.character_name}:</b>
                    </p>
                    <small style={{ marginBottom: '1rem' }}>
                        {note.created_at}
                    </small>
                    <p>{note.note}</p>
                    {delete_button}
                </div>
            );
        }
    }
}

export default class CampaignNotes extends Component {
    constructor(props) {
        super(props);

        let campaign_notes = [];
        if (this.props.campaign_notes) {
            campaign_notes = this.props.campaign_notes;
        }

        let campaign = {};
        if (this.props.campaign) {
            campaign = this.props.campaign;
        }

        this.state = {
            campaigns: [],
            campaign: campaign,
            notes: campaign_notes,
            note_saved: false,
            loaded: false,
        };
    }

    componentDidMount() {
        let user = window.secret_react_state.user;
        //if logged in and campaign chosen
        if (this.props.campaign && this.props.campaign['id']) {
            this.setState({ loaded: true });
        } else if (user && user.id) {
            //if logged in and no campaign chosen
            ajax_wrapper(
                'GET',
                `/api/home/campaign/list/?owner=${user.id}`,
                {},
                this.campaigns_callback,
            );
        } else {
            //else
            this.setState({ loaded: true });
        }
    }

    campaigns_callback = (data) => {
        this.setState({ campaigns: data, loaded: true });
    };

    save_note = (data, callback) => {
        let user = window.secret_react_state.user;
        if (user) {
            data['user'] = user.id;
        } else {
            data['user'] = null;
        }

        data['campaign'] = this.props.campaign['id'];

        data['location'] = this.props.page_location.location_id;
        ajax_wrapper(
            'POST',
            '/api/home/campaignnote/',
            data,
            this.save_callback,
        );

        callback();
    };

    save_callback = (data) => {
        let user = window.secret_react_state.user;
        if (user) {
            data['user'] = { id: user.id, name: user.email };
        }

        let notes = this.state.notes;
        notes.unshift(data);
        this.setState({ notes: notes });

        let defaults = JSON.parse(localStorage.getItem('campaign_defaults'));
        if (!defaults) {
            defaults = {};
            defaults[this.state.campaign.id] = {
                character_name: data['character_name'],
            };
        } else {
            defaults[this.state.campaign.id] = {
                character_name: data['character_name'],
            };
        }
        localStorage.setItem('campaign_defaults', JSON.stringify(defaults));
    };

    render() {
        console.log(
            'Campaign Notes Campaign',
            this.state.campaign,
            this.props.campaign,
        );
        let user = window.secret_react_state.user;
        let dm = this.props.dm;

        let default_character_name = null;

        let defaults = JSON.stringify(
            localStorage.getItem('campaign_defaults'),
        );
        if (defaults && defaults[this.state.campaign.id]) {
            default_character_name =
                defaults[this.state.campaign.id]['character_name'];
        }
        let campaign_settings = null;
        if (dm) {
            campaign_settings = (
                <div style={{ margin: '20px 0px 20px 0px' }}>
                    <h4>Campaign Settings</h4>
                    <Button
                        type="success"
                        onClick={() =>
                            navigator.clipboard.writeText(window.location.href)
                        }
                    >
                        Invite Link <i class="bi bi-copy"></i>
                    </Button>
                    <CampaignSettings
                        campaign_id={this.state.campaign.id}
                        location_id={this.props.page_location.location_id}
                        campaign_location_settings={
                            this.props.campaign_location_settings
                        }
                    />

                    <div style={{ height: '20px' }}></div>
                </div>
            );

            if (!default_character_name) {
                default_character_name = 'DM';
            }
        }

        let notes = null;

        //not logged in
        if (!user && !this.state.campaign.id) {
            notes = (
                <div>
                    <p>
                        This is a feature to add custom notes for your own
                        Campaign.
                    </p>
                    <h6>Available Now</h6>
                    <ul>
                        <li>
                            Take notes on specific locations that only you as
                            the DM can see.
                        </li>
                    </ul>

                    <h6>Coming Soon</h6>
                    <ul>
                        <li>
                            Player Maps, no location info until you toggle a
                            location on.
                        </li>
                        <li>
                            Show players either only just the name, notes you
                            want to share with them or the wiki entry + notes.
                        </li>
                        <li>
                            Player notes: Players can take notes on locations
                            shared with them that the DM also has access to.
                        </li>
                    </ul>

                    <p>
                        {' '}
                        Only available for signed in users.{' '}
                        <Button href="/signup/" type="success">
                            Sign Up Now!
                        </Button>
                    </p>
                </div>
            );
        } else if (user && !this.state.campaign.id) {
            //logged in, no campaigns
            if (this.state.campaigns.length == 0) {
                notes = (
                    <Loading loaded={this.state.loaded}>
                        <p>
                            This is a feature to add custom notes for your own
                            Campaign. However, you haven't made a campaign yet,{' '}
                            <a href="/campaigns/">make one here.</a>
                        </p>
                    </Loading>
                );
            } else {
                //logged in, has campaigns, none chosen.
                let campaign_list = [];
                for (var campaign of this.state.campaigns) {
                    campaign_list.push(
                        <li>
                            <a
                                href={location_to_url(
                                    this.props.page_location.name,
                                    campaign.id,
                                )}
                            >
                                {campaign.name}
                            </a>
                        </li>,
                    );
                }

                notes = (
                    <div>
                        <p>
                            This is a feature to add custom notes for your own
                            Campaign. Choose which campaign below or{' '}
                            <a href="/campaigns/">make a new one here.</a>
                        </p>
                        <ul>{campaign_list}</ul>
                    </div>
                );
            }
        } else if (this.state.campaign.id) {
            //logged in and selected campaign
            let key = 'note_' + this.state.notes.length;

            let notes_list = [];
            for (let note of this.state.notes) {
                notes_list.push(
                    <Note
                        note={note}
                        campaign={this.state.campaign}
                        refresh_notes={this.get_notes}
                        dm={dm}
                    />,
                );
            }

            let defaults = { private: false };
            let private_question = null;
            if (user) {
                private_question = (
                    <Select
                        options={[
                            {
                                text: "Private, don't share with anyone.",
                                value: true,
                            },
                            {
                                text: 'Public, my character basically shouted it out loud.',
                                value: false,
                            },
                        ]}
                        label="Private or Public?"
                        name="private"
                    ></Select>
                );
            }

            notes = (
                <Loading loaded={this.state.loaded}>
                    <h4>Campaign: {this.state.campaign.name}</h4>
                    <h5>Location: {this.props.page_location.name}</h5>
                    {campaign_settings}

                    <div>
                        <h4>Notes</h4>
                        <Form
                            submit={this.save_note}
                            key={key}
                            defaults={defaults}
                        >
                            <TextInput
                                name="character_name"
                                label="Character Name"
                                default={default_character_name}
                            />
                            <TextArea name="note" label="Note" />
                            {private_question}
                        </Form>
                        <div style={{ height: '20px' }}></div>

                        {notes_list}
                    </div>
                </Loading>
            );
        }

        return notes;
    }
}
