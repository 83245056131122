import React, { Component } from 'react';
import { Image } from 'react-konva';

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
export default class CanvasImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };

        this.loadImage = this.loadImage.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = (event) => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image,
        });

        if (this.props.on_load) {
            this.props.on_load(this.image);
        }
    };

    render() {
        return (
            <Image
                onClick={this.props.onClick}
                x={this.props.x || 0}
                y={this.props.y || 0}
                scale={this.props.scale || { x: 1, y: 1 }}
                image={this.state.image}
                opacity={this.props.opacity || 1}
            />
        );
    }
}
