import React, { Component } from 'react';

import { Group, Text, Rect } from 'react-konva';

export default class TextLabel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
        };

        this.update_text = this.update_text.bind(this);
        this.handle_drag_end = this.handle_drag_end.bind(this);
    }

    componentDidMount() {
        this.update_text();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.size != prevProps.size ||
            this.props.label_wrap_ratio != prevProps.label_wrap_ratio
        ) {
            this.reset_text();
        }
    }

    reset_text() {
        this.setState(
            {
                width: 0,
                height: 0,
            },
            this.update_text,
        );
    }

    update_text() {
        let allowed_width = this.props.size * this.props.label_wrap_ratio;

        let width = 0;
        let height = 0;
        if (this.textNode) {
            if (this.textNode.width() > allowed_width) {
                width = allowed_width;
                height =
                    this.textNode.height() *
                    Math.ceil(this.textNode.width() / allowed_width);
            } else {
                width = this.textNode.width();
                height = this.textNode.height();
            }
        }

        this.setState({
            width: width,
            height: height,
        });
    }

    handle_drag_end(event) {
        let new_position = [
            event.currentTarget['attrs']['x'],
            event.currentTarget['attrs']['y'] / -1 - this.state.height / 2 - 10,
        ];
        console.log(new_position);

        if (this.props.onDragEnd) {
            this.props.onDragEnd(event, new_position);
        }
    }

    render() {
        let allowed_width = this.state.width || null;
        let text_height = this.state.height;

        let position = { x: 0, y: 0 };
        if (this.props.alignment === 'top') {
            position.x = -1 * (allowed_width / 2);
            position.y = -1 * (text_height / 2);
        }

        let opacity = text_height === 0 ? 0 : this.props.opacity || 1;

        let text_props = {
            opacity: opacity,
            text: this.props.text,
            fontSize: this.props.size,
            width: allowed_width,
            wrap: 'word',
            fontFamily: 'Mentor',
            align: 'center',
            x: position.x,
            y: position.y,
        };

        var highlight = null;
        if (this.props.highlighted) {
            highlight = (
                <Text
                    {...text_props}
                    stroke="red"
                    strokeWidth={text_props['fontSize'] / 4}
                />
            );
        }

        return (
            <Group
                key="text_group"
                onClick={this.props.onClick}
                x={this.props.label_x_offset || 0}
                y={-1 * (this.props.label_y_offset + text_height / 2 + 10) || 0}
                rotation={this.props.rotation}
                draggable={this.props.draggable}
                onDragEnd={this.handle_drag_end}
            >
                {highlight}
                {/* <Rect
                    stroke="red"
                    strokeWidth={0.5}
                    width={allowed_width}
                    height={text_height}
                    y={position.y}
                    x={position.x}
                />*/}
                <Text
                    {...text_props}
                    stroke="rgb(250 245 213)"
                    strokeWidth={this.props.size / 5}
                />
                <Text
                    ref={(node) => {
                        this.textNode = node;
                    }}
                    {...text_props}
                />
            </Group>
        );
    }
}
