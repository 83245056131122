import $ from 'jquery';

function ajax_wrapper_file(type, url, data, returnFunc) {
    if (window.location.hostname === 'localhost' && !url.includes(':8000')) {
        url = 'http://localhost:8000' + url;
    }

    $.ajax({
        type,
        url,
        data,

        dataType: 'json',
        processData: false,
        contentType: false,

        success(value) {
            returnFunc(value);
        },
        error(xhr, _status, _error) {
            console.log(xhr.responseText);
        },
    });
}

export default ajax_wrapper_file;
