import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

export default class MapSidebar extends Component {
    render() {
        let width = '30%';
        if (isMobile) {
            width = '100%';
        }
        return (
            <div
                style={{
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    width: width,
                    height: '100%',
                    backgroundColor: 'white',
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
