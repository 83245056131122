import React, { Component } from 'react';

import { Circle, Line, Group } from 'react-konva';

import { CanvasImage } from 'components';

class Highlight extends React.Component {
    render() {
        var scale = 1.2;

        return (
            <Circle
                scale={{ x: scale, y: scale }}
                radius={this.props.size}
                fill={'red'}
            />
        );
    }
}

export default class Dot extends Component {
    render() {
        var highlight = null;
        if (this.props.highlighted) {
            highlight = <Highlight size={this.props.size} />;
        }

        return (
            <Group
                key="dot_group"
                onClick={this.props.onClick}
                x={this.props.x || 0}
                y={this.props.y || 0}
                draggable={this.props.draggable}
                onDragEnd={(event) =>
                    this.props.onDragEnd(event, this.props.index)
                }
            >
                {highlight}
                <Circle radius={this.props.size} fill={'rgb(250 245 213)'} />
                <Circle radius={this.props.size * 0.7} fill={'black'} />
            </Group>
        );
    }
}
