function sort_by_keys(array, keys) {
    return array.sort((a, b) => {
        let x = a;
        let y = b;

        keys.forEach((key) => {
            if (x == null || typeof x === 'undefined' || !(key in x)) {
                x = null;
            } else {
                x = x[key];
            }

            if (y == null || typeof y === 'undefined' || !(key in y)) {
                y = null;
            } else {
                y = y[key];
            }
        });

        if (x == null || typeof x === 'undefined') return 1;
        if (y == null || typeof y === 'undefined') return -1;

        if (x < y) return -1;
        if (y < x) return 1;
        return 0;
    });
}

export default function sort_objects(array, keys) {
    return sort_by_keys(array, keys);
}
