import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    MapViewer,
    MapEditor,
    ManageParents,
    LocationList,
    LocationsWithoutImages,
    EditLocation,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    CampaignList,
    EditCampaign,
    CampaignMap,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                index: true,
                element: <MapViewer />,
            },
            {
                path: ':name',
                element: <MapViewer />,
            },
            {
                path: 'dnd_5e_monster_stat_block/:name',
                element: <CurrentNotOffered />,
            },
            {
                path: 'privacy_policy',
                element: <PrivacyPolicy />,
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'signup',
                element: <SignUp />,
            },
            {
                path: 'campaign/:campaign_id/:name',
                element: <MapViewer />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: 'maps',
                element: <LocationList />,
            },
            {
                path: 'campaigns',
                element: <CampaignList />,
            },
            {
                path: 'edit_campaign/:id',
                element: <EditCampaign />,
            },
            {
                path: 'edit_campaign/',
                element: <EditCampaign />,
            },
            
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                index: true,
                element: <AdminDashboard />,
            },
            //
            //Add specific admin pages here

            {
                path: 'manage_parents',
                element: <ManageParents />,
            },

            {
                path: 'edit_location',
                element: <EditLocation />,
            },
            {
                path: 'edit_location/:id',
                element: <EditLocation />,
            },
            {
                path: 'locations_without_images',
                element: <LocationsWithoutImages />,
            },
            //
            //Generic admin page
            {
                path: 'list/:app/:modelname',
                element: <ObjectList />,
            },
            {
                path: 'manage/:app/:modelname/',
                element: <ManageObject />,
            },
            {
                path: 'manage/:app/:modelname/:id',
                element: <ManageObject />,
            },
            {
                path: ':name/edit',
                element: <MapEditor />,
            },
        ],
    },
];

export default routes;
