import React, { Component } from 'react';

import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
const GOOGLE_CLIENT_ID =
    '531056339342-q2dif28ma2pt7c795qrom5muttv6cg8g.apps.googleusercontent.com';

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: null,
            error: '',
            data: {},

            waiting_for_response: false,
        };

        this.submit = this.submit.bind(this);
        this.login_callback = this.login_callback.bind(this);
        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);
    }

    componentDidMount() {
        let client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'email',
            callback: (response) => this.google_login(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client: client });
    }

    submit(state) {
        if (state['password'] == state['password_doublecheck']) {
            let data = {
                email: state['email'],
                password: state['password'],
            };
            data.email = data.email.toLowerCase();

            ajax_wrapper('POST', '/user/register/', data, this.login_callback);
        } else {
            this.setState({ error: 'Passwords Do Not Match' });
        }
    }

    login_callback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            if (localStorage.getItem('redirect')) {
                let url = localStorage.getItem('redirect');
                localStorage.removeItem('redirect');
                window.location.href = url;
            } else {
                window.location.href = '/';
            }
        }
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper(
                'POST',
                '/user/google_login/',
                state,
                this.login_callback,
            );
        }
    }

    google_error(state) {
        console.log('Google Error', state);
        this.setState({ error: state['message'] });
    }

    render() {
        let error = null;
        if (this.state.error !== '') {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ border: '1px solid black' }}
                >
                    <div
                        style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            lineHeight: '20px',
                        }}
                    >
                        {'Login with Google'}
                    </div>
                    <img
                        style={{
                            width: '20px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        return (
            <Container>
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-md-8 col-sm-12">
                        <div className="simple-card">
                            <h2>Customize Maps To Your Campaign</h2>
                            <br />
                            <Alert type="success">
                                <h6>Available Now</h6>
                                <ul>
                                    <li>
                                        Take notes on specific locations that
                                        only you as the DM can see.
                                    </li>
                                </ul>
                            </Alert>
                            <Alert type="info">
                                <h6>Coming Soon</h6>
                                <ul>
                                    <li>
                                        Player Maps, no location info until you
                                        toggle a location on.
                                    </li>
                                    <li>
                                        Show players either only just the name,
                                        notes you want to share with them or the
                                        wiki entry + notes.
                                    </li>
                                    <li>
                                        Player notes: Players can take notes on
                                        locations shared with them that the DM
                                        also has access to.
                                    </li>
                                    <li>
                                        Save filters to only show locations that
                                        you care about. (By source and edition)
                                    </li>
                                </ul>
                            </Alert>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="simple-card">
                            <br />
                            <div style={{ textAlign: 'center' }}>
                                {google_button}
                            </div>

                            <div
                                style={{
                                    textAlign: 'center',
                                    display: 'block',
                                    margin: '30px',
                                }}
                            >
                                OR
                            </div>

                            <Form submit={this.submit} submit_text="Submit">
                                <TextInput
                                    name="email"
                                    label="Email"
                                    required={true}
                                />
                                <TextInput
                                    type="password"
                                    name="password"
                                    label="Password"
                                    required={true}
                                />
                                <TextInput
                                    type="password"
                                    name="password_doublecheck"
                                    label="Confirm Password"
                                    required={true}
                                />
                                <br />
                            </Form>
                            {error}
                            <br />

                            <p>
                                By creating an account you agree to our{' '}
                                <a
                                    href="/privacy_policy"
                                    style={{ color: 'dodgerblue' }}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

export default SignUp;
