import React, { Component } from 'react';

import { Form, TextInput, Container, Loading } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageObject extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];
        let url = `/api/${params['app']}/${params['modelname']}/`;
        if ('id' in params) {
            url = `${url}${params['id']}/`;
        }

        this.state = {
            loaded: false,
            data: {},

            url: url,
        };
    }

    componentDidMount() {
        ajax_wrapper(
            'GET',
            this.state.url,
            {},
            function (value) {
                this.setState({ loaded: true, data: value });
            }.bind(this),
        );
    }

    render() {
        let data = {
            name: this.state.data['name'],
        };

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={data}
                        submit_url={this.state.url}
                        redirect={function () {
                            let params = get_global_state()['params'];
                            window.location.href = `/admin/list/${params['app']}/${params['modelname']}/`;
                        }}
                    >
                        <TextInput name="name" label="Name" />
                    </Form>
                </Loading>
            </Container>
        );
    }
}
