import React, { Component } from 'react';
import { Loading, Button, Form, Select, TextInput, Alert } from 'library';
import { ajax_wrapper } from 'functions';

export class Location extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let location_rows = [
            <tr>
                <td>
                    <a href={this.props.location.wiki_url} target="_blank">
                        {this.props.location.name}
                    </a>
                </td>
            </tr>,
            <tr>
                <NewParent location={this.props.location} />
            </tr>,
        ];

        for (let parent of this.props.location.parents) {
            location_rows.push(<ParentRow parent={parent} />);
        }

        return location_rows;
    }
}

export class ParentRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            removed: false,
        };

        this.remove = this.remove.bind(this);
    }

    remove() {
        let url = '/api/delete_location_position/';

        ajax_wrapper(
            'POST',
            url,
            { id: this.props.parent.position_on_parent_id },
            () => this.setState({ removed: true }),
        );
    }

    render() {
        if (this.state.removed) {
            return null;
        } else {
            return (
                <tr>
                    <td></td>
                    <td>{this.props.parent.name}</td>

                    <td>
                        <Button type="danger" onClick={this.remove}>
                            Remove
                        </Button>
                    </td>
                </tr>
            );
        }
    }
}

export class NewParent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            success: '',
        };

        this.find_and_add_parent = this.find_and_add_parent.bind(this);
        this.update_parent = this.update_parent.bind(this);
    }

    find_and_add_parent(data, callback) {
        console.log('Form Submit', data);
        let url = '/api/find_and_add_parent/';
        ajax_wrapper(
            'POST',
            url,
            {
                parent_name: data['parent_name'],
                location_id: this.props.location.id,
            },
            this.update_parent,
        );
        callback();
    }

    update_parent(data) {
        this.setState(data);
    }

    render() {
        let error = null;
        if (this.state.error != '') {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let success = null;
        if (this.state.success != '') {
            success = <Alert type="success">{this.state.success}</Alert>;
        }

        return (
            <tr>
                <td>
                    <Form submit={this.find_and_add_parent}>
                        <TextInput name="parent_name" />
                    </Form>
                </td>
                <td>
                    {error}
                    {success}
                </td>
                <td></td>
            </tr>
        );
    }
}

export default class ManageParents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            locations: [],
            num_parents: 0,
        };

        this.get_locations = this.get_locations.bind(this);
        this.query_parents = this.query_parents.bind(this);
        this.change_num_parents = this.change_num_parents.bind(this);
    }

    componentDidMount() {
        this.query_parents();
    }

    change_num_parents(name, data) {
        console.log('Change', data);
        this.setState(data, this.query_parents);
    }

    query_parents() {
        let url =
            '/api/locations_with_parents/?num_parents=' +
            this.state.num_parents;

        ajax_wrapper('GET', url, {}, this.get_locations);
    }

    get_locations(data) {
        this.setState({ locations: data, loaded: true });
    }

    render() {
        let location_list = [];
        for (let location of this.state.locations) {
            location_list.push(<Location location={location} />);
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="container">
                    <h2>Manage Parents</h2>
                    <Form
                        defaults={this.state}
                        auto_set_global_state={true}
                        set_global_state={this.change_num_parents}
                    >
                        <Select
                            name="num_parents"
                            options={[
                                { value: 0, text: 0 },
                                { value: 1, text: 1 },
                                { value: 2, text: '2+' },
                            ]}
                        />
                    </Form>
                    <table className="table" key={this.state.num_parents}>
                        <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Parent Name</th>
                                <th>Remove Parent</th>
                            </tr>
                        </thead>
                        <tbody>{location_list}</tbody>
                    </table>
                </div>
            </Loading>
        );
    }
}
