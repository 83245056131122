import React, { Component } from 'react';

export default class LoadLastURL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: null,
        };
    }

    componentDidMount() {
        if (localStorage.getItem('redirect')) {
            let url = localStorage.getItem('redirect');
            localStorage.removeItem('redirect');
            window.location.href = url;
        } else {
            window.location.href = '/';
        }
    }

    render() {
        return <div></div>;
    }
}
