import { HEADER_HEIGHT } from 'constants';

function get_position_on_map(input, map_position, zoom) {
    var position = [
        (input[0] - map_position[0]) / zoom,
        (input[1] - HEADER_HEIGHT - map_position[1]) / zoom,
    ];

    return position;
}

export default get_position_on_map;
