import React, { Component } from 'react';

import { Alert } from 'library';

export default class LocationsLoading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let height = '130px';
        if (this.props.children_loaded) {
            height = '0px';
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    width: '100%',
                    zIndex: 10,
                    textAlign: 'center',
                    transition: 'height .5s ease-in-out',
                    height: height,
                    overflow: 'hidden',
                }}
            >
                <Alert
                    type="warning"
                    style={{
                        display: 'inline-block',
                        width: 'auto',
                    }}
                >
                    <div>
                        <div
                            className="spinner-grow"
                            style={{
                                margin: '10px',
                                color: 'rgb(102, 77, 3)',
                            }}
                            role="status"
                        ></div>

                        <div
                            className="spinner-grow"
                            style={{
                                animationDelay: '150ms',
                                margin: '10px',
                                color: 'rgb(102, 77, 3)',
                            }}
                            role="status"
                        ></div>

                        <div
                            className="spinner-grow"
                            style={{
                                animationDelay: '300ms',
                                margin: '10px',
                                color: 'rgb(102, 77, 3)',
                            }}
                            role="status"
                        ></div>
                    </div>
                    <b>{`Loading locations (${this.props.count} / ${this.props.total})`}</b>
                </Alert>
            </div>
        );
    }
}
