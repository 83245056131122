import React, { Component } from 'react';

export default class EditShapeStatus extends Component {
    render() {
        if (!this.props.editing_shape) {
            return false;
        }

        let next_steps = 'Click to add first point';

        return <div>Editing Shape : {next_steps}</div>;
    }
}
