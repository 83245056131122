import React, { Component } from 'react';

export default class ZoomButtons extends Component {
    render() {
        return (
            <div
                style={{
                    zIndex: '10',
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    textAlign: 'center',
                    lineHeight: '0px',
                    boxShadow: '1px 1px 6px rgba(0,0,0,.3)',
                    borderRadius: '6px',
                    fontSize: '1.6rem',
                    textAlign: 'center',
                    color: '#666',
                    background: 'white',
                }}
            >
                <div>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '4px',
                        }}
                        onClick={() =>
                            this.props.zoom_in(this.props.mouse_position)
                        }
                    >
                        <i class="bi bi-plus bold dark-hover"></i>
                    </div>
                </div>
                <div
                    style={{
                        margin: '0px 4px',
                        borderBottom: '2px solid #ccc',
                    }}
                ></div>
                <div>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '4px',
                        }}
                        onClick={() =>
                            this.props.zoom_out(this.props.mouse_position)
                        }
                    >
                        <i class="bi bi-dash bold dark-hover"></i>
                    </div>
                </div>
            </div>
        );
    }
}
