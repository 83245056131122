function is_right_click(event) {
    var is_right = false;
    event = event || window.event;

    if ('which' in event) {
        // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
        is_right = String(event.which) === '3';
    } else if ('button' in event) {
        // IE, Opera
        is_right = String(event.button) === '2';
    } else if ('evt' in event) {
        return is_right_click(event['evt']);
    }

    return is_right;
}

export default is_right_click;
