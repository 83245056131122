import React, { Component } from 'react';

import { Container } from 'library';

export default class CurrentNotOffered extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <h2>
                    Sorry, we have decided to commit 100% of our work toward
                    Awesome Maps for DnD
                </h2>
                <p>
                    Unfortunaly, we've decided to commit ourselves to focusing
                    solely on providing the best ways to explore DnD through
                    maps and so we are no longer offering this feature.
                </p>
                <p>
                    From here, you can either check out{' '}
                    <a href="/sword_coast/">The Sword Coast Map</a> or{' '}
                    <a href="https://discord.gg/cYvcWSUxTn">Join Our Discord</a>{' '}
                    and tell us that we need to offer more than just maps.
                </p>
            </Container>
        );
    }
}
