function get_url() {
    let url = window.location.pathname;
    if (url[0] === '/') {
        url = url.substring(1);
    }
    if (url[url.length - 1] === '/') {
        url = url.substring(0, url.length - 1);
    }
    const urlSplit = url.split('/');

    let params = {};
    Object.keys(urlSplit).forEach((index) => {
        params[index] = urlSplit[index];
    });

    params = Object.assign(params, get_search_parameters());

    return params;
}

function get_search_parameters() {
    const { href } = window.location;
    let params = {};

    if (href.indexOf('?') > -1) {
        const postParams = href.split('?')[1];
        const splitParams = postParams.split('&');

        Object.keys(splitParams).forEach((index) => {
            const temp = splitParams[index];
            const tempSplit = temp.split('=');

            const [firstSplit, secondSplit] = tempSplit;
            params[firstSplit] = secondSplit;
        });
    }

    return params;
}

export { get_url, get_search_parameters };
