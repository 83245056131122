import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Link, Container, Button, Loading, Form, TextInput } from 'library';

class Item extends Component {
    render() {
        return (
            <div className="simple-card">
                <Link
                    href={`/admin/manage/${this.props.params['app']}/${this.props.params['modelname']}/${this.props.data['id']}/`}
                >
                    {this.props.data['name']}
                </Link>
            </div>
        );
    }
}

export default class ObjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            list: [],

            filter: {},
            limit: 100,
            offset: 0,
        };

        this.update_list = this.update_list.bind(this);
        this.load_list = this.load_list.bind(this);
    }

    componentDidMount() {
        this.update_list();
    }

    update_list() {
        this.setState(
            { loaded: false },
            function () {
                let params = get_global_state()['params'];

                let url = `/api/${params['app']}/${params['modelname']}/list/?limit=${this.state.limit}&offset=${this.state.offset}`;
                for (let key in this.state.filter) {
                    url += `&${key}=${this.state.filter[key]}`;
                }

                ajax_wrapper('GET', url, {}, this.load_list);
            }.bind(this),
        );
    }

    load_list(value) {
        this.setState({ loaded: true, list: value });
    }

    render() {
        let params = get_global_state()['params'];

        let object_list = [];
        for (let item of this.state.list) {
            object_list.push(<Item data={item} params={params} />);
        }

        let new_url = `/admin/manage/${params['app']}/${params['modelname']}/`;

        let next_button = null;
        if (this.state.list.length == this.state.limit) {
            next_button = (
                <Button
                    type="primary"
                    onClick={() =>
                        this.setState(
                            {
                                offset: this.state.offset + this.state.limit,
                            },
                            this.update_list,
                        )
                    }
                >
                    Next
                </Button>
            );
        }

        let previous_button = null;
        if (this.state.offset > 0) {
            previous_button = (
                <Button
                    type="primary"
                    onClick={() =>
                        this.setState(
                            {
                                offset: this.state.offset - this.state.limit,
                            },
                            this.update_list,
                        )
                    }
                >
                    Previous
                </Button>
            );
        }

        return (
            <Container>
                <h2>{`This page will load ${params['modelname']} model`}</h2>
                <div>
                    <Button type="primary" href={new_url}>
                        Add New
                    </Button>
                </div>
                <div className="simple-card-container">
                    <div className="simple-card">
                        <Form
                            defaults={this.state.filter}
                            submit_text="Search"
                            submit={(state, callback) =>
                                this.setState(
                                    { filter: state },
                                    function () {
                                        callback();
                                        this.update_list();
                                    }.bind(this),
                                )
                            }
                        >
                            <TextInput name="name__icontains" label="Name" />
                        </Form>
                    </div>
                    <div className="simple-card">
                        <div>{`Showing ${this.state.list.length} items.  Offset by ${this.state.offset}`}</div>
                        {previous_button}
                        {next_button}
                    </div>
                </div>
                <div className="simple-card-container">
                    <Loading loaded={this.state.loaded}>{object_list}</Loading>
                </div>
            </Container>
        );
    }
}
