import React, { Component } from 'react';

import { Button, Tabs } from 'library';
import { location_to_url } from 'functions';
import { EditLocationHasPosition, WikiPageContent } from 'components';

export default class PointOfInterestEditor extends Component {
    render() {
        let location_has_position = this.props.location_has_position;

        let tabs = {};

        let go_to_map = (
            <Button
                type="warning"
                href={location_to_url(location_has_position.name)}
            >
                Add Map For {location_has_position.name}
            </Button>
        );
        if (location_has_position.image) {
            go_to_map = (
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    href={location_to_url(location_has_position.name)}
                >
                    Venture To {location_has_position.name} Map
                </Button>
            );
        }

        tabs['Edit POI'] = (
            <div>
                {go_to_map}
                <EditLocationHasPosition
                    location_has_position={location_has_position}
                    handle_selected_poi_update={
                        this.props.handle_selected_poi_update
                    }
                    editing_shape={this.props.editing_shape}
                    move_position={this.props.move_position}
                    toggle_editing_shape={this.props.toggle_editing_shape}
                    toggle_shape_type={this.props.toggle_shape_type}
                    de_activate={this.props.de_activate}
                    update_text_callback={this.props.update_text_callback}
                />
            </div>
        );

        let info = <p>We do not have a link to the wiki for this location</p>;
        if (
            location_has_position &&
            location_has_position['wiki_page_content'] &&
            location_has_position['wiki_page_content'].length > 0
        ) {
            info = (
                <WikiPageContent
                    name={location_has_position['name']}
                    image={location_has_position['wiki_image']}
                    image_text={location_has_position['wiki_image_text']}
                    data={location_has_position['wiki_page_content']}
                />
            );
        }
        tabs['Location Info'] = <div>{info}</div>;

        return <Tabs tabs={tabs} />;
    }
}
