import React, { Component } from 'react';

import { Circle, Line, Group } from 'react-konva';

import { CanvasImage } from 'components';

class Highlight extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var scale = 1.2;
        var size = this.props.image_size;

        var red = 'red'; //'rgb(209,72,66)';

        var widest_percent = 0.36;
        var indent_percent = 0.9;
        var height_indent_percent = 0.45;

        var circle_position = [size[0] / 2, size[1] * widest_percent];
        var line_points = [
            -1 * circle_position[0] * indent_percent,
            (size[0] / 2) * height_indent_percent,
            0,
            size[1] - circle_position[1],
            circle_position[0] * indent_percent,
            (size[0] / 2) * height_indent_percent,
        ];

        var lines = [
            <Circle
                scale={{ x: scale, y: scale }}
                x={circle_position[0]}
                y={circle_position[1]}
                radius={size[0] / 2}
                fill={red}
            />,
            <Line
                scale={{ x: scale, y: scale }}
                x={circle_position[0]}
                y={circle_position[1]}
                closed={true}
                key={this.props.id}
                fill={red}
                points={line_points}
            />,
        ];

        return lines;
    }
}

export default class Pin extends Component {
    render() {
        let scale = this.props.width / this.props.image_size[0];

        var highlight = null;
        if (this.props.highlighted) {
            highlight = <Highlight image_size={this.props.image_size} />;
        }

        return (
            <Group
                scale={{
                    x: scale,
                    y: scale,
                }}
            >
                {highlight}
                <CanvasImage
                    src={
                        'https://mapquest-public.s3.amazonaws.com/22_05_09_13_50_13_construction_marker.png'
                    }
                    on_load={this.props.on_load}
                />
            </Group>
        );
    }
}
