import React, { Component } from 'react';
import { Loading, Form, TextInput, Button } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';

export default class LocationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            locations: [],
            name: '',
        };

        this.get_locations = this.get_locations.bind(this);
        this.query_locations = this.query_locations.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.query_locations();
    }

    search(name, data) {
        console.log('Change', data);
        this.setState(data, this.query_locations);
    }

    query_locations() {
        let url = '/api/location_search/?name=' + this.state.name;

        ajax_wrapper('GET', url, {}, this.get_locations);
    }

    get_locations(data) {
        this.setState({ locations: data, loaded: true });
    }

    render() {
        let location_list = [];
        for (let location of this.state.locations) {
            location_list.push(
                <tr>
                    <td>{location.name}</td>
                    <td>
                        <a href={location_to_url(location.name)}>
                            Venture Forth
                        </a>
                    </td>
                    <td>
                        <a href={`/admin/edit_location/${location.id}/`}>
                            Edit Location
                        </a>
                    </td>
                </tr>,
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="container">
                    <h2>Location List</h2>
                    <Button type="primary" href="/admin/edit_location/">
                        Add New Location
                    </Button>
                    <Form
                        defaults={this.state}
                        auto_set_global_state={true}
                        set_global_state={this.search}
                    >
                        <TextInput name="name" label="Search By Name" />
                    </Form>
                    <table className="table" key={this.state.num_parents}>
                        <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Venture There</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>{location_list}</tbody>
                    </table>
                </div>
            </Loading>
        );
    }
}
