window.secret_react_state = {};

function set_global_state(name, state) {
    if (state !== null && typeof state === 'object') {
        const newState = window.secret_react_state[name] || {};
        Object.keys(state).forEach((index) => {
            newState[index] = state[index];
        });
        window.secret_react_state[name] = newState;
    } else {
        window.secret_react_state[name] = state;
    }
}

function get_global_state() {
    return window.secret_react_state;
}

export { set_global_state, get_global_state };
