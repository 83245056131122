import { Outlet } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';

import { set_global_state, get_search_parameters } from 'functions';
import { Header, Footer } from 'components';

export default function AnonymousRoutes() {
    let params = Object.assign(get_search_parameters(), useParams());
    set_global_state('params', params);
    let location = useLocation();
    set_global_state('location', location);

    return (
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}
