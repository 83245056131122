import React, { Component } from 'react';

import { HEADER_HEIGHT } from 'constants';

import { Form, TextInput, Button, Alert, Select } from 'library';
import { ajax_wrapper, UserValidator, location_to_url } from 'functions';

export default class EditLocationHasPosition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };

        this.de_activate = this.de_activate.bind(this);
        this.change_parent = this.change_parent.bind(this);
        this.parent_change_callback = this.parent_change_callback.bind(this);

        this.update_text = this.update_text.bind(this);
        this.update_text_callback = this.update_text_callback.bind(this);
    }

    de_activate() {
        ajax_wrapper(
            'POST',
            '/api/deactivate_location_has_position/',
            { id: this.props.location_has_position.id },
            this.props.de_activate,
        );
    }

    change_parent(data) {
        ajax_wrapper(
            'POST',
            '/api/change_position_parent/',
            {
                id: this.props.location_has_position.id,
                new_parent: data['parent'],
            },
            this.parent_change_callback,
        );
    }

    parent_change_callback(data) {
        if (data['error']) {
            this.setState({ error: data['error'] });
        } else {
            this.props.de_activate();
        }
    }

    update_text(data, form_callback) {
        ajax_wrapper(
            'POST',
            `/api/change_position_text/${this.props.location_has_position.id}/`,
            {
                scale: data['scale'],
                lat: data['lat'],
                lng: data['lng'],
                label_x_offset: parseFloat(data['label_x_offset']),
                label_y_offset: parseFloat(data['label_y_offset']),
                label_wrap_ratio: parseFloat(data['label_wrap_ratio']),
                rotation: data['rotation'],
                display_dot: data['display_dot'],
                geojson: data['geojson'],
            },
            this.update_text_callback,
        );

        form_callback();
    }

    update_text_callback(data) {
        console.log('Update Text Callback', data);
        this.props.update_text_callback(data);
    }

    render() {
        let location_has_position = this.props.location_has_position;
        let button_style = { display: 'block', marginBottom: '10px' };

        let error = null;
        if (this.state.error !== '') {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let edit_shape = (
            <Button
                type="warning"
                onClick={this.props.toggle_editing_shape}
                style={button_style}
            >
                Edit Shape
            </Button>
        );
        if (this.props.editing_shape) {
            edit_shape = (
                <Button
                    type="primary"
                    onClick={this.props.toggle_editing_shape}
                    style={button_style}
                >
                    Stop Editing Shape
                </Button>
            );
        }
        let toggle_shape_type = (
            <Button
                type="warning"
                onClick={this.props.toggle_shape_type}
                style={button_style}
            >
                Change Shape Type
            </Button>
        );

        return (
            <div>
                <div style={{ marginBottom: '20px' }}>
                    <Form submit={this.change_parent}>
                        <TextInput name="parent" label="Parent" />
                    </Form>

                    {error}
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Form
                        submit={this.update_text}
                        defaults={location_has_position}
                        auto_set_global_state={true}
                        set_global_state={this.props.handle_selected_poi_update}
                        uses_global_dict
                    >
                        <TextInput name="scale" label="Scale" />
                        <TextInput
                            name="label_x_offset"
                            label="label_x_offset"
                        />
                        <TextInput
                            name="label_y_offset"
                            label="label_y_offset"
                        />
                        <TextInput
                            name="label_wrap_ratio"
                            label="label_wrap_ratio"
                        />
                        <TextInput name="rotation" label="Rotation" />
                        <Select
                            options={[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' },
                            ]}
                            name="display_dot"
                            label="Display Dot?"
                        />
                    </Form>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Button
                        type="warning"
                        onClick={this.props.move_position}
                        style={button_style}
                    >
                        Move Position
                    </Button>

                    {edit_shape}
                    {toggle_shape_type}
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Button
                        type="danger"
                        deleteType={true}
                        onClick={this.de_activate}
                    >
                        De-activate
                    </Button>
                </div>
            </div>
        );
    }
}
