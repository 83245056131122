function location_to_url(location_name, campaign_id = null) {
    let url_name = location_name
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll("'", '__');

    if (campaign_id) {
        return `/campaign/${campaign_id}/${url_name}/`;
    } else {
        return `/${url_name}/`;
    }
}

export default location_to_url;
