import { Navigate, Outlet } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';

import {
    UserValidator,
    set_global_state,
    get_search_parameters,
} from 'functions';
import { Header, Footer } from 'components';

export default function AdminRoutes() {
    let params = Object.assign(get_search_parameters(), useParams());
    set_global_state('params', params);
    let location = useLocation();
    set_global_state('location', location);

    let checker = new UserValidator();
    let is_staff = checker.is_staff();

    return is_staff ? (
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
    ) : (
        <Navigate to="/" />
    );
}
