import React, { Component } from 'react';
import { Loading, Form, TextInput, Button } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';

export default class CampaignMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            campaigns: [],
        };

        this.get_campaigns = this.get_campaigns.bind(this);
        this.query_locations = this.query_locations.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.query_campaigns();
    }

    query_campaigns() {
        let url = '/api/get_campaigns/?user=' + this.state.user.id;

        ajax_wrapper('GET', url, {}, this.get_campaigns);
    }

    get_campaigns(data) {
        this.setState({ campaigns: data, loaded: true });
    }

    render() {
        let location_list = [];

        return (
            <Loading loaded={this.state.loaded}>
                <div className="container">
                    <h2>Campaign List</h2>
                    <Button type="primary" href="/admin/edit_campaign/">
                        Add New Campaign
                    </Button>

                    <table className="table" key={this.state.num_parents}>
                        <thead>
                            <tr>
                                <th>Campaign Name</th>
                                <th>Venture There</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>{location_list}</tbody>
                    </table>
                </div>
            </Loading>
        );
    }
}
