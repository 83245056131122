export default function geojson_has_points(data) {
    if (
        data &&
        data['geometry'] &&
        data['geometry']['coordinates'] &&
        data['geometry']['coordinates'].length > 0
    ) {
        return true;
    }

    return false;
}
