import React, { Component } from 'react';
import { Loading, Form, FileInput, Button, TextInput } from 'library';
import { ajax_wrapper } from 'functions';

class WikiImage extends Component {
    constructor(props) {
        super(props);

        this.submit_wiki_image = this.submit_wiki_image.bind(this);
    }

    submit_wiki_image() {
        let url = '/api/upload_image_from_wiki/';

        ajax_wrapper(
            'POST',
            url,
            {
                image_url: this.props.image_url,
                location_id: this.props.location_id,
            },
            this.props.image_submitted,
        );
    }

    render() {
        return (
            <div className="col-3">
                <img
                    alt={this.props.image_url}
                    style={{ height: 'auto', maxWidth: '100%' }}
                    src={this.props.image_url}
                />
                <Button type="primary" onClick={this.submit_wiki_image}>
                    Choose Image{' '}
                </Button>
            </div>
        );
    }
}

class Location extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image_submitted: false,
        };

        this.submit_image = this.submit_image.bind(this);
        this.image_submitted = this.image_submitted.bind(this);
    }

    submit_image(data) {}

    image_submitted() {
        this.setState({ image_submitted: true });
    }

    render() {
        if (this.state.image_submitted) {
            return null;
        } else {
            let location = this.props.location;

            let image_list = [];
            for (let image_url of location.images_from_wiki) {
                image_list.push(
                    <WikiImage
                        image_url={image_url}
                        location_id={location.id}
                        image_submitted={this.image_submitted}
                    />,
                );
            }

            return (
                <div>
                    <p>
                        <a
                            href={location.wiki_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {location.name}
                        </a>
                    </p>
                    <Form submit={this.submit_image}>
                        <FileInput name="image_url" />
                    </Form>
                    <div className="row">{image_list}</div>
                </div>
            );
        }
    }
}

export default class LocationWithoutImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            locations: [],
            name: '',
            offset: 0,
        };

        this.get_locations = this.get_locations.bind(this);
        this.query_locations = this.query_locations.bind(this);
        this.update_offset = this.update_offset.bind(this);
    }

    componentDidMount() {
        this.query_locations();
    }

    update_offset(name, data) {
        this.setState(data, this.query_locations);
    }

    query_locations() {
        let url = `/api/locations_without_images/?offset=${this.state.offset}&parent_id=b0afd170-91d4-40f7-96c2-74b4b44f6730`;

        ajax_wrapper('GET', url, {}, this.get_locations);
    }

    get_locations(data) {
        this.setState({ locations: data, loaded: true });
    }

    render() {
        let location_list = [];
        for (let location of this.state.locations) {
            location_list.push(
                <Location key={location.id} location={location} />,
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="container">
                    <h2>Location List</h2>
                    <Form
                        defaults={this.state}
                        auto_set_global_state={true}
                        set_global_state={this.update_offset}
                    >
                        <TextInput name="offset" />
                    </Form>
                    {location_list}
                </div>
            </Loading>
        );
    }
}
