import React from 'react';

class Tab extends React.Component {
    render() {
        let tab_style = {};
        if (!this.props.active) {
            tab_style = { backgroundColor: 'white' };
        }
        return (
            <div
                onClick={(event) => this.props.select_tab(this.props.name)}
                className={`tab-header col-${this.props.columns} ${
                    this.props.active ? 'active' : ''
                } ${this.props.tab_class}`}
                style={tab_style}
            >
                <div className="tab-name">{this.props.name}</div>
            </div>
        );
    }
}

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        const initial_tab =
            this.props.initial_tab || Object.keys(this.props.tabs)[0];

        this.state = {
            selected_tab: initial_tab,
        };

        this.select_tab = this.select_tab.bind(this);
    }

    select_tab(name) {
        this.setState({ selected_tab: name });
        if (name == 'Close') {
            this.props.select_poi(null, null);
        }
    }

    render() {
        let columns = Math.floor(12 / Object.keys(this.props.tabs).length);

        const tabs = [];
        const tabs_content = [];
        for (let key in this.props.tabs) {
            tabs.push(
                <Tab
                    tab_class={this.props.tab_class}
                    columns={columns}
                    name={key}
                    active={key == this.state.selected_tab}
                    select_tab={(name) => this.select_tab(name)}
                />,
            );

            let tab_style = {
                opacity: '0',
                height: '1px',
                width: '1px',
                overflowY: 'scroll',
            };
            if (this.state.selected_tab == key) {
                tab_style = {};
            }

            tabs_content.push(
                <div
                    style={tab_style}
                    className={`justify-content-center tab-content col-12`}
                >
                    {this.props.tabs[key]}
                </div>,
            );
        }

        return (
            <div className="tab-list" style={{ height: '100%' }}>
                <div
                    className={'tab-header-container row'}
                    style={{ margin: '0px' }}
                >
                    {tabs}
                </div>
                <div
                    className="simple-card-container"
                    style={{
                        height: `calc(100% - 50px)`,
                        overflowY: 'scroll',
                        zIndex: 100,
                    }}
                >
                    <div className="simple-card">{tabs_content}</div>
                </div>
            </div>
        );
    }
}
